.Footer-bg-bar {
  position: relative;
  width: 100%;
  background-color: #000;
  z-index: 2;
}
.Footer-container {
  display: flex;
  font-family: 'AvertaSemiBold', sans-serif;
  padding: 1em  0;

  .Footer-copyright {
    color: #777;
    font-size: 12px;
    text-align: left;
    flex-grow: 1;
  }
  .Footer-privacy-terms {
    text-align: right;
    flex-shrink: 1;
    font-size: 12px;
    color: #555;
    a {
      color: #999;
      text-decoration: none;
    }
  }
}