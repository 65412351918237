.ProductList-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  flex-wrap: wrap;

  .FixedSizeProduct-container {
    position: relative;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    display: block;
    width: 50%;
    margin: 0 0 1em 0;
    padding: 0;

    &:nth-child(odd) {
      padding-right: 0.25em;
    }
    &:nth-child(even) {
      padding-left: 0.25em;
    }

    .FixedSizeProduct-image-container {
      max-width: 100%;
      width: 100%;
      height: auto;

      .aspect-spacer {
        display: block;
        padding-top: 100%;
        z-index: 0;
        height: 0;
        width: 0;
      }

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    h4 {
      max-width: 100%;
    }
  }



  .ProductList-no-results-container {
    width: 100%;
    text-align: center;
    min-height: 80vh;

    h2 {
      font-family: AvertaExtraBoldItalic, sans-serif;
    }
  }
}

@media screen and (min-width: 555px) {
  .ProductList-container {
    .FixedSizeProduct-container {
      flex-basis: 33.3%;
      max-width: 33.3%;
      width: 33.3%;
      &:nth-child(odd) {
        padding-right: 0;
      }
      &:nth-child(even) {
        padding-left: 0;
      }
      &:nth-child(3n) {
        padding-left: 0.42em;
      }
      &:nth-child(3n-1) {
        padding: 0 0.21em;
      }
      &:nth-child(3n-2) {
        padding-right: 0.42em;
      }
    }
  }
}

@media screen and (min-width: 850px) {
  .ProductList-container {
    .FixedSizeProduct-container {
      flex-basis: 25%;
      max-width: 25%;
      width: 25%;

      &:nth-child(4n-3) {
        padding: 0 0.4em 0 0;
      }
      &:nth-child(4n-2) {
        padding: 0 0.3em 0 0.2em;
      }
      &:nth-child(4n-1) {
        padding: 0 0.2em 0 0.3em;
      }
      &:nth-child(4n) {
        padding: 0 0 0 0.4em;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .ProductList-container {
    .FixedSizeProduct-container {
      flex-basis: 33.3%;
      max-width: 33.3%;
      width: 33.3%;

      &:nth-child(3n) {
        padding: 0 0 0 0.42em;
      }
      &:nth-child(3n-1) {
        padding: 0 0.21em;
      }
      &:nth-child(3n-2) {
        padding: 0 0.42em 0 0 ;
      }
    }
    .ProductList-no-results-container {
      min-height: 30vh;
    }
  }
}

@media screen and (min-width: 1264px) {
  .ProductList-container {
    .FixedSizeProduct-container {
      flex-basis: 25%;
      max-width: 25%;
      width: 25%;
      padding: 0 0.33em;

      &:nth-child(4n-3) {
        padding: 0 0.4em 0 0;
      }
      &:nth-child(4n-2) {
        padding: 0 0.3em 0 0.2em;
      }
      &:nth-child(4n-1) {
        padding: 0 0.2em 0 0.3em;
      }
      &:nth-child(4n) {
        padding: 0 0 0 0.4em;
      }
    }
  }
}
