.SearchSuggestions-bg-bar {
  width: 100%;
  background-color: #121313;
}

.SearchSuggestions-container {
  text-align: left;
  .SearchSuggestions-suggestions {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      height: 0;
      display: none;
    }

    a {
      display: inline-block;
      text-decoration: none;
      color: #fff;
    }

    .SuggestionBox-container {
      position: relative;
      display: flex;
      align-items: center;
      margin: 1.25em 0.5em;
      height: 6em;
      min-height: 6em;
      max-height: 6em;
      justify-content: center;
      width: 9em;
      min-width: 9em;
      max-width: 9em;
      border: 2px solid #2f2f2f;
      border-radius: 0.25em;
      text-align: center;
      overflow: hidden;
      background-size: cover;

      .SuggestionBox-dark-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        z-index: 1;
      }
      h4 {
        position: relative;
        z-index: 2;
        margin: 0.5em;
        font-size: 1.25em;
        text-align: center;
        color: #fff;
        font-family: "AvertaExtraBold", sans-serif;
        text-decoration: none;
        white-space: normal;
        text-shadow: 0 0.1em 1em rgba(0,0,0, 0.7);
      }
    }
  }
}