.Brands-container {

  .Brands-a-z {
    text-align: left;

    ul {
      position: relative;
      display: block;
      overflow-x: scroll;
      overflow-y: hidden;
      text-align: left;
      white-space: nowrap;
      list-style: none;
      margin: 1.25em 0;
      padding: 0;
      height: 1em;
      max-height: 1em;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        height: 0;
        display: none;
      }

      li {
        display: inline-block;
        margin: 0;
        padding: 0 1em;
        text-indent: 0;
        border-right: 1px solid #ccc;
        &:last-child {
          border-right: none;
        }
        a {
          color: #000;
          text-decoration: none;
          display: inline-block;
          height: 1em;
        }
      }
    }
  }

  h3 {
    margin: 1.5em 0;
    text-align: left;
  }

  hr {
    background: #ccc;
    box-shadow: none;
    border: none;
    height: 1px;
    margin: 1em 0;
  }

  ul.brand-list {
    list-style: none;
    margin: 0 0 2em 0;
    padding: 0;
    text-align: left;

    li {
      display: block;
      width: 100%;
      margin: 1em 0;

      a {
        color: #000;
        text-decoration: none;
      }
    }
  }

  .Brand-letter {
    position: relative;
    display: block;
    width: 100%;

    h4 {
      font-family: "AvertaExtraBold", sans-serif;
      color: #333;
      font-size: 1.33em;
      width: 0.8em;
      text-align: left;
      padding: 0.5em 0;
      border-bottom: 2px solid #333;
    }
  }
}


@media screen and (min-width: 390px) {
  .Brands-container {
    ul.brand-list {
      li {
        float: left;
        display: block;
        width: 49%;
        margin: 0.6em 0;

        a {
          color: #000;
          text-decoration: none;
        }
      }
      &:after {
        content:"";
        display:block;
        clear:both;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .Brands-container {
    ul.brand-list {
      li {
        font-size: 0.9em;
        width: 33.3%;
      }
    }
  }
}

@media screen and (min-width: 860px) {
  .Brands-container {
    .Brands-a-z {
      ul {
        position: relative;
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: left;
        white-space: normal;
        list-style: none;
        height: auto;
        max-height: initial;
        li {
          margin: 0.33em 0;
          width: 1em;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .Brands-container {
    .Brands-a-z {
      ul li {
      }
    }
    ul.brand-list {
      li {
        font-size: 0.9em;
        width: 25%;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .Brands-container {
    .Brands-a-z {
      ul {
        li {
          font-size: 0.91em;
          margin: 0;
          width: auto;
        }
      }
    }
    ul.brand-list {
      li {
        font-size: 0.9em;
        width: 20%;
      }
    }
  }
}
