.CategoryWizardOverlay-gender-select {
  .genderButton {
    position: relative;
    font-family: AvertaExtraBoldItalic, sans-serif;
    border: 3px solid #2f2f2f;;
    color: #fff;
    background-color: #000;
    background-image: url('../../public/imgs/wizard_women.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    border-radius: 8px;
    min-height: 30vh;
    height: 5.75em;
    font-size: 2.1em;
    margin-bottom: 0.5em;
    overflow: hidden;
    .overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0,0,0,0.25);
      text-shadow: 1px 1px 4px rgba(0,0,0,0.33), 0 0 0.5em rgba(0,0,0, 0.7), 0 0 2em rgba(0,0,0,1);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
    }
    &.men {
      background-image: url('../../public/imgs/wizard_men.jpg');
    }
  }
}

.CategoryWizardOverlay-category-select {
  ul {
    list-style: none;
    li {
      padding: 0;
      margin: 1.25em 0;
      text-align: left;

      button, a {
        padding: 0;
        text-align: left;
        font-size: 1.2em;
        text-decoration: none;
        display: inline;
        border: none;
        background: none;
        color: #fff;
        font-family: AvertaSemiBold, sans-serif;
        &.shopAll {
          font-family: AvertaExtraBold, sans-serif;
        }
      }
    }
  }
}

@media screen and (min-width: 850px) {
  .CategoryWizardOverlay-gender-select {
    padding-top: 5vh;
    margin-left: -50%;
    width: 200%;
    .genderButton {
      width: 48%;
      &:first-child {
        margin-right: 1%;
      }
      &:last-child {
        margin-left: 1%;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .CategoryWizardOverlay-gender-select {
    padding-top: 5vh;
    margin-left: -100%;
    width: 300%;
    .genderButton {
      min-height: 35vh;
      height: 10em;
    }
  }
}