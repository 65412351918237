.Hero-container {
  position: relative;
  width: 100%;
  min-height: 200px;
  background-color: #333;
  padding-top: 4.4em;
  padding-bottom: 3.75em;
  font-size: 16px;
  background-position: center center;
  background-attachment: local;
  background-size: cover;

  .Hero-overlay {
    background: rgba(0,0,0,0.525);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  h1 {
    position: relative;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: 'AvertaExtraBoldItalic', sans-serif;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    line-height: 1.35em;
    margin: 1.5em 0.7em 1.5em 0.7em;
    font-weight: normal;
  }
  h2 {
    display: none;
    position: relative;
    font-family: 'Averta', sans-serif;
    font-weight: normal;
    font-size: 13px;
    color: #fff;
    margin: -1em 2.5em 1em 2.5em;
    line-height: 1.2em;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  }
  .SearchBox-container {
    margin: 2em 2em 1.75em 2em;
    .SearchBox-border {
      border-width: 0;
      .SearchBox-form {
        input {
          font-size: 16px;
        }
        button {
          width: 1.75em;
          height: 1.75em;
        }
      }
    }
  }
  .Hero-browser-button {
    position: relative;
    font-size: 15px;
    color: #fff;
    border: none;
    font-family: 'AvertaExtraBold', sans-serif;
    background-image: url('../svg/round_button.svg');
    background-color: transparent;
    background-size: 100% 100%;
    background-position: center center;
    padding: 0.7em 1.15em 0.95em 1.15em;
    cursor: pointer;
  }
}

@media screen and (min-width: 374px) {
  .Hero-container {
    h1 {
      font-size: 26px;
    }
  }
}

@media screen and (min-width: 406px) {
  .Hero-container {
    h1 {
      font-size: 28px;
    }
  }
}

@media screen and (min-width: 450px) {
  .Hero-container {
    font-size: 17px;
    h1 {
      font-size: 28px;
      line-height: 1.25em;
      margin: 1em;
    }
    h2 {
      display: block;
      font-size: 14px;
      margin: -1em 2.8em 1em 2.8em;
    }
    .SearchBox-container {
      margin: 2em 2.7em;
    }
  }
}
@media screen and (min-width: 500px) {
  .Hero-container {
    padding-top: -4.35em;
    font-size: 18px;
    h1 {
      max-width: 560px;
      margin-right: auto;
      margin-left: auto;
      font-size: 34px;
    }
    h2 {
      font-size: 15px;
      max-width: 480px;
      margin-right: auto;
      margin-left: auto;
      margin-top: -1.5em;
    }
    .SearchBox-container {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
      .SearchBox-border {
        border-width: 2px;
        .SearchBox-form {
          input {
            font-size: 18px;
            margin-left: 0.9em;
          }
          button {
            width: 1.6em;
            height: 1.6em;
          }
        }
      }
    }
    .Hero-browser-button {
      font-size: 16px;
    }
  }
}

@media screen and (min-width: 650px) {
  .Hero-container {
    font-size: 19px;
    padding-top: -4.84em;
    padding-bottom: 2.5em;
    z-index: 1;
    h1 {
      max-width: 600px;
      font-size: 40px;
    }
    h2 {
      font-size: 16px;
      max-width: 500px;
    }
    .SearchBox-container {
      max-width: 520px;
      margin-top: 2.33em;
      margin-bottom: 1.5em;
      .SearchBox-border .SearchBox-form button {
        width: 1.7em;
        height: 1.7em;
      }
    }
    .Hero-browser-button {
      font-size: 17px;
    }
  }
}

@media screen and (min-width: 850px) {
  .Hero-container {
    font-size: 20px;
    padding-top: -5em;
    h1 {
      margin-top: 1.4em;
    }
    h2 {
      font-size: 17px;
      max-width: 550px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .Hero-container {
    font-size: 21px;
    padding-bottom: 2.75em;
    h1 {
      max-width: 600px;
      font-size: 44px;
    }
    .SearchBox-container {
      max-width: 550px;
      margin-bottom: 1.75em;
      .SearchBox-border {
        .SearchBox-form {
          input {
            font-size: 20px;
            margin-left: 1em;
          }
          button {
            width: 1.9em;
            height: 1.9em;
          }
        }
      }
    }
    .Hero-browser-button {
      font-size: 19px;
      padding: 0.7em 1.25em 1.05em 1.25em;
    }
  }
}

@media screen and (min-width: 1600px) {
  .Hero-container {
    padding-bottom: 4em;
    h1 {
      margin-top: 1.75em;
      max-width: 700px;
      font-size: 48px;
    }
    h2 {
      font-size: 19px;
      max-width: 580px;
      margin-top: -1.75em;
    }
    .SearchBox-container {
      max-width: 610px;
      .SearchBox-border {
        .SearchBox-form {
          input {
            font-size: 20px;
            margin-left: 1em;
          }
          button {
            width: 1.9em;
            height: 1.9em;
          }
        }
      }
    }
    .Hero-browser-button {
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 2000px) {
  .Hero-container {
    padding-bottom: 6.5em;
    h1 {
      margin-top: 2.25em;
      max-width: 800px;
      font-size: 60px;
    }
    h2 {
      font-size: 26px;
      max-width: 800px;
      margin-top: -1em;
    }
    .SearchBox-container {
      max-width: 700px;
      .SearchBox-border {
        .SearchBox-form {
          input {
            font-size: 24px;
            margin-left: 1.1em;
          }
          button {
            width: 2.5em;
            height: 2.5em;
          }
        }
      }
    }
    .Hero-browser-button {
      font-size: 26px;
    }
  }
}