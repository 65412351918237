.PopDownHeader-container {
  background: #000;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  text-align: center;

  .Header-container {
    position: relative;

    .Header-logo {
      margin-top: 0.48em;
      width: 2em;
    }

    .Header-header {
      height: 3em;
      nav {
        margin-top: 0;
        ul {
          margin-top: 0.7em;
        }
      }

      .SearchBox-container {
        margin-top: 0.575em;
      }
    }
  }
}

@media screen and (min-width: 650px) {
  .PopDownHeader-container {
    .Header-container {
      .Header-header {
        nav {
          ul {
            margin-top: 0.575em;
          }
        }
        .SearchBox-container {
          margin-top: 0.5em;
        }
      }
    }
  }
}

@media screen and (min-width: 850px) {
  .PopDownHeader-container {
    .Header-container {
      .Header-header {
        nav {
          ul {
            margin-top: 0.7em;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .PopDownHeader-container {
    .Header-container {
      .Header-logo {
        margin-left: 4.85vw;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .PopDownHeader-container {
    .Header-container {
      .Header-logo {
        margin-left: 1.9em;
      }
    }
  }
}
