.ProductDetails-container {
  margin-top: 0.5em;
  min-height: calc(100vh - 8em);


  .ProductImageContainer {
    position: relative;
    overflow-x: hidden;
    width: 100%;

    .ProductImageSwipeContainer {
      position: relative;
      width: 100%;
      min-width: 100%;
      .Carousel-chevron  {
        display: none;
      }
    }

    .ImageSelectionContainer {
      display: none;
    }
    .FlickerImageContainer {
      display: flex;
      height: 320px;
      width: 100%;
      img {
        align-self: center;
        object-fit: contain;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0.5em;
      }
    }
    .FlickingDotContainer {
      width: 100%;
      text-align: center;
      .FlickingDot {
        width: 0.6em;
        height: 0.6em;
        background: #CCC;
        display: inline-block;
        margin: 1em 0.32em;
        border-radius: 1em;
        transition: background-color 333ms;
        &.active {
          background: #222;
        }
      }
    }
  }

  .ProductDetailsContainer {
    text-align: left;
    h2 {
      font-family: "AvertaSemiBold", sans-serif;
      font-weight: normal;
      font-size: 1.2em;
      line-height: 1.35em;
      margin: 0.5em 0 1em 0;
    }
    .ProductPrice {
      font-family: 'AvertaExtraBold', sans-serif;
      font-size: 1.3em;
      margin-bottom: 1.1em;
    }
    .Buy-Container {
      a {
        display: inline-block;
        text-decoration: none;
        width: 100%;
        text-align: center;
        background-color: #111;
        color: #fff;
        font-family: 'AvertaExtraBoldItalic', sans-serif;
        font-size: 1.2em;
        padding: 0.8em 0;
        border-radius: 10em;
        span {
          display: inline-block;
          color: #e5e5e5;
          font-family: "AvertaSemiBold", sans-serif;
          font-size: 0.9em;
          &.divider {
            font-size: 1em;
            margin: 0 0.1em;
            color: #ccc;
            font-family: 'Averta', sans-serif;
          }
        }
      }
    }

    .ProductAttrsContainer {
      margin: 2em 0 1em 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin: 0.25em 0;
          padding: 0.75em 0;
          border-bottom: 1px solid #ccc;
          &:after {
            content:"";
            display:block;
            clear:both;
          }

          span {
            display: inline-block;
          }
          .AttrLabel {
            float: left;
          }
          .Attr {
            float: right;
          }
        }
      }
    }
    .ProductDetails {
      margin-bottom: 3em;
      h6 {
        font-family: "AvertaSemiBold", sans-serif;
        margin: 0.5em 0 1em 0;
        font-size: 1em;
        font-weight: normal;
      }
      .ProductDescription {
        position: relative;
        white-space: pre-wrap;
        font-size: 0.9em;

        &.collapsed {
          max-height: 300px;
          overflow: hidden;
        }

        button {
          position: absolute;
          cursor: pointer;
          top: 260px;
          font-family: 'AvertaExtraBold', sans-serif;
          font-size: 0.8em;
          color: #000;
          text-transform: uppercase;
          letter-spacing: 1px;
          left: 0;
          height: 40px;
          width: 100%;
          border: 0;
          background: rgb(255,255,255);
          background: linear-gradient(0deg, rgba(255,255,255,1.0) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.8) 75%, rgba(255,255,255,0.5) 100%);
        }
      }
    }
  }
}

@media screen and (min-width: 390px) {
  .ProductDetails-container {
    .ProductImageContainer {
      .FlickerImageContainer {
        height: 375px;
      }
    }
  }
}

@media screen and (min-width: 475px) {
  .ProductDetails-container {
    .ProductImageContainer {
      .FlickerImageContainer {
        height: 400px;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .ProductDetails-container {
    .ProductImageContainer {
      .FlickerImageContainer {
        height: 450px;
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .ProductDetails-container {
    display: flex;
    margin-top: 1em;

    .ProductImageContainer {
      flex-shrink: 1;
      flex-grow: 1;
      position: relative;
      overflow-x: visible;
      width: auto;

      .ProductImageSwipeContainer {
        position: relative;
        .Carousel-chevron  {
          cursor: pointer;
          position: absolute;
          top: 50%;
          margin-top: -1.25em;
          height: 2.5em;
          display: block;
          z-index: 10;
          -webkit-filter: drop-shadow(0 2px 2px rgba(0,0,0,.05));
          filter: drop-shadow(0 2px 2px rgba(0,0,0,.05));
          transition: all 200ms;
          &:hover {
            margin-top: -1.5em;
          }
          &.left {
            left: -1em;
          }
          &.right {
            right: -1em;
          }
          &.hidden {
            display: none;
          }
        }
      }

      .ImageSelectionContainer {
        display: block;
        ul {
          display: block;
          position: relative;
          list-style: none;
          margin: 1.5em 0 0 0;
          padding: 0;
          text-align: left;
          width: 100%;
          line-height: 0;
          li {
            position: relative;
            display: inline-block;
            width: 18.4%;
            margin-right: 2%;
            margin-bottom: 2%;
            line-height: 0;
            .spacer {
              margin-top: 100%;
            }
            &:nth-child(5n) {
              margin-right: 0;
            }

            button {
              opacity: 0.65;
              border: none;
              border-radius: 4px;
              position: absolute;
              cursor: pointer;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              background-position: center;
              background-size: cover;
              &.active {
                border: 2px solid #222;
                opacity: 1;
              }
            }
          }
        }
      }
      .FlickerImageContainer {
        display: flex;
        height: 420px;
        width: 100%;
        img {
          align-self: center;
          object-fit: contain;
          margin: auto;
          max-width: 100%;
          max-height: 100%;
          border-radius: 0.5em;
        }
      }
      .FlickingDotContainer {
        display: none;
      }
    }

    .ProductDetailsContainer {
      font-size: 0.85em;
      width: 350px;
      margin-left: 2em;
      flex-shrink: 0;
      text-align: left;
    }
  }
}

@media screen and (min-width: 900px) {
  .ProductDetails-container {
    .ProductImageContainer {
      .FlickerImageContainer {
        height: 475px;
      }
    }

    .ProductDetailsContainer {
      width: 375px;
      margin-left: 2.25em;
    }
  }
}

@media screen and (min-width: 1024px) {
  .ProductDetails-container {
    .ProductImageContainer {
      .FlickerImageContainer {
        height: 550px;
      }
    }
    .ProductDetailsContainer {
      font-size: 0.825em;
      h2 {
        font-size: 1.25em;
      }
      .Buy-Container {
        font-size: 0.95em;
      }
      .ProductDetails {
        .ProductDescription {
          font-size: 0.9em;
        }
      }
    }
  }
}

@media screen and (min-width: 1100px) {
  .ProductDetails-container {
    .ProductImageContainer {
      .ImageSelectionContainer {
        ul {
          margin: 1.75em 0 0 0;

          li {
            width: 15%;
            margin-right: 2%;
            margin-bottom: 2%;
            line-height: 0;

            &:nth-child(5n) {
              margin-right: 2%;
            }
            &:nth-child(6n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1250px) {
  .ProductDetails-container {
    .ProductImageContainer {
      .FlickerImageContainer {
        height: 600px;
      }
      .ImageSelectionContainer {
        ul {
          margin: 1.75em 0 0 0;

          li {
            width: 9.3%;
            margin-right: 2%;
            margin-bottom: 2%;
            line-height: 0;

            .spacer {
              margin-top: 100%;
            }

            &:nth-child(6n) {
              margin-right: 2%;
            }
            &:nth-child(9n) {
              margin-right: 0;
            }
          }
        }
      }
      .ProductImageSwipeContainer {
        .Carousel-chevron {
          &.right {
            right: 0;
          }
          &.left {
            left: 0;
          }

        }
      }
    }
  }
}
