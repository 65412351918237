.Error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  flex-direction: column;

  h2 {
    font-weight: normal;
    font-family: AvertaExtraBoldItalic, sans-serif;
    font-size: 1.75em;
    width: 100%;
  }
  p {
    font-size: 1em;
  }
}

.Text-container {
  text-align: left;
  max-width: 600px;
  margin: 1em auto;
  padding: 0 1em;
}

@media screen and (min-width: 600px) {
  .Error-container {
    min-height: 50vh;
    h2 {
      font-size: 2.5em;
    }
    p {
      font-size: 1.15em;
    }
  }
}

@media screen and (min-width: 900px) {
  .Error-container {
    min-height: 60vh;
    h2 {
      font-size: 4em;
    }
    p {
      font-size: 1.5em;
    }
  }
}