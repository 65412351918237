.FixedSizeProduct-container {
  text-align: left;
  margin: 0 1.5em 1.5em 0;
  &.empty {
    .FixedSizeProduct-image-container {
      .FixedSizeProduct-image-blur-bg {
        opacity: 0;
      }
      img {
        opacity: 0;
      }
    }
    a {
      text-decoration: none;
      color: #000;
    }
    h4 {
      min-height: 1.25em;
      background: #f5f5f5;
      color: #000;
      text-decoration: none;
    }
    .FixedSizeProduct-price {
      min-height: 1em;
      background: #f5f5f5;
      width: 10%;
      color: #000;
      text-decoration: none;
    }
    .FixedSizeProduct-size {
      display: none;
    }
  }
  &.populated {
    .FixedSizeProduct-image-container {
      .FixedSizeProduct-image-blur-bg {
        opacity: 1;
      }
      img {
        opacity: 1;
      }
    }
    h4, .FixedSizeProduct-price {
      min-height: initial;
      background: none;
      color: #000;
      text-decoration: none;
    }
    a {
      text-decoration: none;
      color: #000;
    }
    .FixedSizeProduct-price {
      width: initial;
      display: inline-block;
      float: left;
      margin-top: 0.1em;
      line-height: 1.25em;
      height: 1.25em;
    }
    .FixedSizeProduct-size {
      line-height: 1.25em;
      height: 1.25em;
      margin-top: 0.1em;
      font-size: 0.65em;
      background: #c5c5c5;
      color: #fff;
      display: inline-block;
      float: right;
      margin-right: 2px;
      padding: 0.1em 0.33em;
      font-family: 'AvertaSemiBold', sans-serif;
      border-radius: 0.25em;
    }
  }
  &.removed {
    display: none !important;
    margin: 0;
    padding: 0;
  }
  .FixedSizeProduct-image-container {
    position: relative;
    background: #eee;
    width: 12em;
    height: 12em;
    border-radius: 8px;
    overflow: hidden;

    &.see-more {
      background: none;
      height: 13em;
      width: 10em;
      .inner {
        position: absolute;
        top:28%;
        background: none;
        width: 100%;
        text-align: center;
        left: 0;
        padding: 0;
        margin: 0;
        a {
          background: #fff;
          border: 3px solid #000;
          color: #000;
          font-family: AvertaExtraBold, sans-serif;
          text-decoration: none;
          display: inline-block;
          border-radius: 4em;
          padding: 0.5em 1.25em;
        }
      }
    }
    .aspect-spacer {
      display: none;
    }

    .FixedSizeProduct-image-blur-bg {
      position: absolute;
      border-radius: 8px;
      overflow: hidden;
      backface-visibility: hidden;
      transition: 300ms all linear;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      -webkit-filter: blur(16px);
      filter: blur(16px);
    }
    &.thrilling-png {
      background: #fff;
      .FixedSizeProduct-image-blur-bg {
        background-size: 200% 200%;
        -webkit-filter: blur(24px);
        filter: blur(24px);
        opacity: 0.3;
        background-color: #fff;
      }
    }
    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 2;
      transition: all 300ms;
    }
    .FixedSizeProduct-gradient-overlay {
      position: absolute;
      border-radius: 8px;
      overflow: hidden;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      background: transparent;
      background: linear-gradient(0deg, rgba(0,0,0,0.12) 0%, rgba(0,0,0,0.01) 25%, rgba(0,0,0,0.01) 92%, rgba(0,0,0,0.04) 100%);
    }
  }
  h4 {
    font-family: "AvertaSemiBold", sans-serif;
    font-size: 0.72em;
    margin: 0.7em 0 0.5em 0;
    max-width: 16.5em;
    padding: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .FixedSizeProduct-price {
    font-size: 0.72em;
  }
}