.SearchOverlay-container {
  font-size: 16px;
  top:0;
  left: 0;
  position: fixed;
  display: block;
  max-height: 100%;
  background: #000;
  color: #fff;
  height: 0;
  overflow: hidden;
  opacity: 0;
  width: 100%;
  transition: height 0ms 200ms, opacity 200ms 0ms;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  z-index: 101;

  .Header-sub-menu-top {
    height: 0;
    overflow: hidden;
    transition: height 0ms 200ms, opacity 200ms 0ms;
  }

  &.active {
    overflow: scroll;
    height: 100%;
    opacity: 1;
    transition: height 0ms 0ms, opacity 300ms 0ms;
    .Header-sub-menu-top {
      height: auto;
      transition: height 0ms 0ms, opacity 300ms 0ms;
    }
    .SearchBox-container {
      display: block;
      position: relative;
      flex-grow: 1;
      margin: 1em;

      .SearchBox-border {
        border: none;
        background: #fff;
        width: 100%;

        .SearchBox-form {
          font-size: 19px;
          display: flex;
          width: 100%;
          padding: 0;
          margin: 0;
          input {
            margin-left: 1em;
          }
          button {
            background: #000;
            width: 1.75em;
            height: 1.75em;
          }
        }
      }
    }
  }
  .SearchOverlay-suggestions {
    margin: 5.75em 1.75em 2em 1.33em;
    h5 {
      font-weight: normal;
      font-family: Averta, sans-serif;
      font-size: 0.93em;
      text-align: left;
      margin-bottom: 1.25em;
      color: #e5e5e5;
    }
    ul {
      list-style: none;
      padding: 0 1.5em;
      li {
        margin: 1.5em 0;
        padding: 0;
        text-align: left;
        a {
          font-size: 1em;
          color: #fff;
          text-decoration: none;
          font-family: AvertaSemiBold, sans-serif;
        }
      }
    }

  }
}

@media screen and (min-width: 480px) {
  .SearchOverlay-container {
    font-size: 18px;
    &.active {
      .SearchBox-container {
        .SearchBox-border {
          .SearchBox-form {
            font-size: 20px;
            button {
              width: 1.85em;
              height: 1.85em;

            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 650px) {
  .SearchOverlay-container {
    display: none;
  }
}