input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.SearchBox-container {
  display: none;
  .SearchBox-border {
    display: flex;
    border-radius: 100em;
    border: 2px solid #4d4f50;
    background: #fff;
    width: 100%;
    .SearchBox-form {
      font-size: 19px;
      display: flex;
      width: 100%;
      padding: 0;
      margin: 0;
      input {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        font-size: 15px;
        flex-grow: 1;
        margin-left: 0.8em;
        border: none;
        background: none;
        padding: 0;
        &:focus {
          outline: none;
        }
      }
      button {
        background: #404142;
        border-radius: 10em;
        cursor: pointer;
        border: none;
        outline: none;
        padding: 0.1em;
        margin: 0.15em 0.25em;
        justify-content: flex-end;
        width: 1.33em;
        height: 1.33em;
        img {
          width: 100%;
          height: 100%;
          filter: invert(99%) sepia(75%) saturate(0%) hue-rotate(29deg) brightness(111%) contrast(100%);
        }
      }
    }
  }
}

.Header-container.hero {
  .SearchBox-container {
    .SearchBox-border {
      display: none;
    }
  }
}
.Hero-container {
  .SearchBox-container {
    position: relative;
    display: flex;
    .SearchBox-border {
      border: none;
      box-shadow: 0 0 12px rgba(0,0,0, 0.5);
      display: flex;
      .SearchBox-form {
        button {
          background: #000;
        }
      }
    }
  }
}

@media screen and (min-width: 650px) {
  .SearchBox-container {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
  }
  .Header-header .SearchBox-container {
    margin: 1.5em 0.25em 0 1em;
    align-items: flex-start;
  }
}

@media screen and (min-width: 850px) {
  .SearchBox-container {
    .SearchBox-form {
      input {
        font-size: 16px;
        margin-left: 1.33em;
        padding: 0;
      }
      button {
        padding: 0.15em;
        margin: 0.2em 0.3em;
        width: 1.45em;
        height: 1.45em;
      }
    }
  }
  .Header-header .SearchBox-container {
    margin: 1.55em 0.75em 0 1.5em;
    align-items: flex-start;
    .SearchBox-form {
      input {
        font-size: 17px;
      }
      button {
        width: 1.55em;
        height: 1.55em;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .Header-header .SearchBox-container {
    margin: 1.55em 3vw 0 4vw;
    .SearchBox-border {
      .SearchBox-form {
        input {
          font-size: 17px;
          margin-left: 1.1em;
        }
        button {
          padding: 0.15em;
          margin: 0.2em 0.3em;
          width: 1.6em;
          height: 1.6em;
        }
      }
    }
  }
}

@media screen and (min-width: 1264px) {
  .Header-header .SearchBox-container {
    margin: 1.5em 0.5vw 0 0;
    .SearchBox-border {
      transition: all 400ms;
      max-width: 232px;
      margin-left: auto;
      margin-right: 0;
      .SearchBox-form {
        input {
          width: 135px;
        }
      }
      &.activated {
        max-width: 95%;
        .SearchBox-form {
          input {
            width: auto;
          }
        }
      }
    }
  }
}
