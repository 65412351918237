.Carousel-container {
  position: relative;
  h3 {
    font-weight: normal;
    font-family: "AvertaExtraBold", sans-serif;
    text-align: left;
    font-size: 1em;
    margin: 1.5em 0;
  }

  .Carousel-chevron {
    display: none;
    position: absolute;
    top: 47%;
    margin-top: -30px;
    width: 60px;
    height: 60px;
    z-index: 5;
    filter: drop-shadow(0px 2px 2px rgb(0 0 0 / 0.1));
    cursor: pointer;
    transition: all 200ms;

    &:hover {
      filter: drop-shadow(0px 3px 3px rgb(0 0 0 / 0.15));
      margin-top: -33px;
    }

    &.left {
      left: -38px;
    }
    &.right {
      right: -38px;
    }
    &.hidden {
      opacity: 0;
      cursor: default;
    }
  }

  .Carousel-products {
    overflow-x: scroll;
    overflow-y: hidden;
    text-align: left;
    white-space: nowrap;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      height: 0;
      display: none;
    }

    .FixedSizeProduct-container {
      display: inline-block;
    }
  }
}

@media screen and (min-width: 1024px) {
  .Carousel-container {
    .Carousel-chevron {
      display: block;
    }
  }
}