.Header-spacer {
    height: 4.4em;
    font-size: 16px;
}
.Header-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    font-size: 16px;
    transition: all 200ms;
    z-index: 100;
}

.Header-header {
    height: 4.4em;
    position: relative;
    transition: all 200ms;
    background: transparent;
    min-width: 100vw;
    display: flex;
    color: white;
    margin-top: 0;
    padding-top: 0.1em;
    font-size: 16px;
    &.hidden-top-pop-down {
        opacity: 0;
        transition: all 0s;
    }
    .Header-logo {
        transition: all 200ms;
        display: block;
        width: 6.25em;
        margin-top: 0.75em;
        align-self: flex-start;
        flex-shrink: 1;
        margin-left: 1em;
    }
    .Header-menu-killer {
        position: absolute;
        top: 0;
        right: 0;
        width: 90%;
        height: 1.6em;
    }
    nav {
        display: block;
        flex-grow: 1;
        margin-right: 0.9em;
        margin-top: 0.45em;
        ul {
            display: flex;
            justify-content: flex-end;
            list-style: none;
            margin-block-end: 0;
            li {
                padding: 0 1em;
                &.Header-link {
                    padding: 0.2em 1em;
                }
                a {
                    transition: all 200ms;
                    font-weight: normal;
                    text-decoration: none;
                    color: #000;
                    font-size: 1em;
                    font-family: 'AvertaExtraBold', sans-serif;
                    .Header-search {
                        height: 1.65em;
                        margin-right: -0.5em;
                        transform: rotate(0.1deg) scale(0.99);
                    }
                }
            }
        }
        .Header-arrow {
            height: 0;
            width: 0;
            display: none;
        }
    }
}

.Header-sub-menu-container {
    overflow: hidden;
    position: fixed;
    background: #000;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    max-height: 100%;
    margin: 0 0 0 0;
    padding: 0;
    z-index: 1;
    color: #fff;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    opacity: 0;
    transition: height 0ms 200ms, opacity 200ms 0ms;

    &.active {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        opacity: 1;
        display: block;
        transition: height 0ms 0ms, opacity 300ms 0ms;
    }

    .Header-sub-menu-links {
        -ms-scroll-chaining: none;
        overscroll-behavior: contain;
        margin: 5.5em 2.7em 2em 2.7em;
        .Header-sub-menu-column {
            margin: 0 0 3.5em 0;
            padding: 0;
            h4 {
                font-family: 'Averta', sans-serif;
                color: #ccc;
                border-bottom: 1px solid #ccc;
                padding-bottom: 0.7em;
                text-align: left;
                font-weight: normal;
                font-size: 1.15em;
            }
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                text-align: left;
                &.larger {
                    font-size: 1.25em;
                }
                &.small {
                    margin-top: -0.9em;
                    display: block;
                    float: left;
                    width: 49%;
                }
                li {
                    margin: 0.9em 0;
                    text-align: left;
                    a {
                        text-decoration: none;
                        color: #fff;
                        font-weight: normal;
                        font-family: 'AvertaSemiBold', sans-serif;
                    }
                    &.browser-all-brands a {
                        font-family: 'AvertaExtraBold', sans-serif;
                    }
                }
            }
            .small-clear {
                clear: both;
            }
        }
    }
}

.Header-sub-menu-top {
    background: #000;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    width: 100%;

    h4 {
        font-weight: normal;
        font-family: 'AvertaExtraBold', sans-serif;
        flex-grow: 1;
        text-align: left;
        padding-left: 1.5em;
        font-size: 1.1em;
        margin: 1.33em 0;

    }
    button {
        background: none;
        border: none;
        flex-shrink: 1;
        padding: 1em 1.5em 0.8em 0;
        img {
            margin: 0;
            padding: 0;
            height: 1.45em;
            width: 1.45em;
        }
    }
}

.Header-spacer.hero {
    display:none;
}

.Header-container.hero {
    background: rgba(255, 255, 255, 0);
    .Header-header {
        .Header-logo {
            filter: invert(99%) sepia(75%) saturate(0%) hue-rotate(29deg) brightness(111%) contrast(100%) drop-shadow( 0px 0px 8px rgba(0, 0, 0, 0.3));
        }
        nav ul li a {
            color: rgb(255,255,255);
            text-shadow: 0 0 4px rgba(0,0,0, 0.33);
            .Header-search {
                filter: invert(99%) sepia(75%) saturate(0%) hue-rotate(29deg) brightness(111%) contrast(100%);
            }
        }
    }
}


.header-width {
    font-size: 16px;
    margin-left: 1em;
    margin-right: 1em;
}

@media screen and (max-width: 370px) {
    .Header-header nav ul {
        padding-inline-start: 0;
    }
}

@media screen and (min-width: 450px) {
    .Header-spacer {
        font-size: 17px;
    }
    .Header-container {
        font-size: 17px;
    }
    .Header-header {
        font-size: 17px;
    }
    .header-width {
        font-size: 17px;
    }
}

@media screen and (min-width: 500px) {
    .Header-spacer {
        font-size: 18px;
    }
    .Header-container {
        font-size: 18px;
    }
    .Header-header {
        font-size: 18px;
        .Header-logo {
            margin-left: 1.1em;
        }
        nav {
            margin-top: 0.32em;
            margin-right: 1em;
            ul li {
                padding: 0 1.2em;
                &.Header-link {
                    padding: 0.2em 1.2em;
                }
            }
        }
    }
    .header-width {
        font-size: 18px;
        margin-right: 1.1em;
        margin-left: 1.1em;
    }
}

@media screen and (min-width: 650px) {
    .Header-spacer {
        font-size: 19px;
    }
    .Header-container {
        font-size: 19px;
    }
    .Header-header {
        font-size: 19px;
        .Header-logo {
            margin-left: 1.33em;
            margin-top: 1.0em;
        }
        nav {
            flex-grow: 0;
            flex-shrink: 1;
            margin-top: 0.45em;
            ul {
                padding-inline-start: 0;
                li {
                    &.Header-link {
                        padding-top: 0.35em;
                    }
                    &.Header-icon {
                        display: none;
                    }
                }
            }
        }
    }
    .header-width {
        font-size: 19px;
        margin-right: 1.33em;
        margin-left: 1.33em;
    }
}

@media screen and (min-width: 850px) {
    .Header-spacer {
        font-size: 20px;
        height: 5em;
    }
    .Header-container {
        font-size: 20px;
    }
    .Header-header {
        height: 5em;
        font-size: 20px;
        .Header-logo {
            margin-top: 1.1em;
            margin-left: 1.7em;
        }
        nav {
            position: relative;
            margin-right: 1.25em;
            margin-top: 0.72em;
            ul {
                li.Header-link {
                    padding: 0.2em 1.35em;
                }
            }
            .Header-arrow {
                height: auto;
                width: 40px;
                display: block;
                position: absolute;
                bottom: -1px;
                left: 2.3em;
                opacity: 0;
                transition: none;

                &.pop-down {
                    width: 32px;
                }

                &.active {
                    opacity: 1.0;
                    transition: opacity 200ms;
                    &.women {
                        left: 2.2em;
                        &.pop-down {
                            left: 2.45em;
                        }
                    }
                    &.men {
                        left: 7.65em;
                        &.pop-down {
                            left: 7.82em;
                        }
                    }
                }
            }
        }
    }
    .header-width {
        font-size: 20px;
        margin-right: 1.7em;
        margin-left: 1.7em;
    }

    .Header-container.hero.active {
        background: rgba(255, 255, 255, 1);
        .Header-header {
            .Header-logo {
                filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%);
            }
            nav ul li a {
                color: rgb(0,0,0);
                text-shadow: none;
                .Header-search {
                    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%);
                }
            }
        }
    }

    .Header-sub-menu-container {
        font-size: 15px;
        overflow: hidden;
        position: relative;
        -ms-scroll-chaining: initial;
        overscroll-behavior: initial;

        &.active {
            overflow: hidden;
        }

        .Header-sub-menu-top {
            display: none;
        }
        .Header-sub-menu-links {
            display: flex;
            margin: 1em 34px;
            .Header-sub-menu-column {
                margin: 0 4em 2em 0;
                padding: 0;
                flex-grow: 1;
                &:last-child {
                    margin-right: 0;
                }
                &:first-child {
                    margin-right: 4em;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .Header-spacer {
        font-size: 21px;
    }
    .Header-container {
        font-size: 21px;
    }
    .Header-header {
        font-size: 21px;
        .Header-logo {
            margin-left: 5vw;
        }
        nav {
            margin-right: 5vw;
            ul {
                li.Header-link {
                    padding: 0.2em 1.5em;
                }
            }
            .Header-arrow {
                &.active {
                    &.women {
                        left: 2.3em;
                        &.pop-down {
                            left: 2.55em;
                        }
                    }
                    &.men {
                        left: 8.2em;
                        &.pop-down {
                            left: 8.3em;
                        }
                    }
                }
            }
        }
    }
    .header-width {
        font-size: 21px;
        margin-right: 5vw;
        margin-left: 5vw;
    }
    .Header-sub-menu-container {
        font-size: 17px;

        .Header-sub-menu-links {
            margin: 1em 5vw;
            .Header-sub-menu-column {
                margin: 0 3.5em 2em 0;
                &:first-child {
                    margin-right: 4.25em;
                }
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .Header-header {
        position: relative;
        width: 1500px;
        min-width: 1500px;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        .Header-logo {
            margin-left: 2em;
        }
        nav {
            margin-right: 2em;
        }
    }
    .header-width {
        max-width: 1416px; // 1500 - (4em * 21px fonts size)
        margin-right: auto;
        margin-left: auto;
    }
    .Header-sub-menu-container {
        font-size: 17px;

        .Header-sub-menu-links {
            max-width: 1416px;
            margin: 1em auto;
            .Header-sub-menu-column {
                margin: 0 3.5em 2em 0;
                &:first-child {
                    margin-right: 4.25em;
                }
            }
        }
    }
}
