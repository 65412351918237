@font-face {
  font-family: 'AvertaExtraBoldItalic';
  src: url('fonts/avertademope-extrabold-webfont.woff2') format('woff2'),
  url('fonts/avertademope-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Averta';
  src: url('fonts/avertademopecutteddemo-regular-webfont.woff2') format('woff2'),
  url('fonts/avertademopecutteddemo-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'AvertaExtraBold';
  src: url('fonts/averta-extrabold-webfont.woff2') format('woff2'),
  url('fonts/averta-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'AvertaSemiBold';
  src: url('fonts/averta-semibold-webfont.woff2') format('woff2'),
  url('fonts/averta-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

html, body {
  padding: 0;
  margin: 0;
  font-family: 'Averta', sans-serif;
  background: #fff;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}
