.FilterList-container {
  font-size: 15px;
  top:0;
  left: 0;
  position: fixed;
  display: block;
  max-height: 100%;
  background: #000;
  color: #fff;
  height: 0;
  overflow: hidden;
  opacity: 0;
  width: 100%;
  transition: height 0ms 200ms, opacity 200ms 0ms;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  z-index: 101;

  .Header-sub-menu-top {
    span {
      display: none;
    }
    .see-results {
      img {
        display: none;
      }
      span {
        display: inline-block;
        background: #fff;
        border-radius: 40px;
        color: #000;
        padding: 0.5em 0.8em 0.6em 0.8em;
        margin-top: -0.2em;
        margin-right: -0.2em;
        font-family: AvertaExtraBold, sans-serif;
      }
    }
  }
  &.active {
    overflow: scroll;
    height: 100%;
    opacity: 1;
    transition: height 0ms 0ms, opacity 300ms 0ms;
    .Header-sub-menu-top {
      transition: height 0ms 0ms, opacity 300ms 0ms;
    }
  }
  &.inactive {
    height: 0;
    overflow: hidden;

    .Header-sub-menu-top {
      height: 0;
      overflow: hidden;
      transition: height 0ms 200ms, opacity 200ms 0ms;
    }
  }

  // SLider Classes
  .rc-slider-rail {
    height: 4px;
    background-color: #888;
    border-radius: 6px;
  }
  .rc-slider-track {
    height: 4px;
    background-color: #fff;
    border-radius: 8px;
  }
  .rc-slider-handle {
    width: 20px;
    height: 20px;
    margin-top: -8px;
    background-color: #000;
    border: solid 4px #fff;
    opacity: 1;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #fff;
    box-shadow: 0 0 0 6px #fff;
  }
  .rc-slider-handle:focus {
    outline: none;
    box-shadow: none;
  }
  .rc-slider-handle:focus-visible {
    border-color: #fff;
    box-shadow: 0 0 0 3px #fff;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: #fff;
  }
  .rc-slider-handle:hover {
    border-color: #fff;
  }
  .rc-slider-handle:active {
    border-color: #fff;
    box-shadow: 0 0 5px #fff;
  }

  .FilterList-filters {
    margin: 5em 1.6em 2em 1.6em;
    transition: none;

    .filter {
      transition: none;
      margin: 0 0 2em 0;
      padding-bottom: 2em;
      border-bottom: 1px solid #444;
      &:last-child {
        border-bottom: none;
      }

      br {
        display: none;
      }

      h5 {
        text-align: left;
        font-weight: normal;
        font-size: 1em;
        margin: 0 0 0.5em 0;
        font-family: AvertaSemiBold, sans-serif;
        color: #eee;
        padding-bottom: 0.7em;
      }

      .gender-button-container {
        display: flex;
        gap: 0.25em;
        width: 100%;
        max-width: 350px;

        button {
          flex-basis: 50%;
          background: none;
          color: #fff;
          font-family: AvertaExtraBold, sans-serif;
          border: 2px solid #fff;
          border-radius: 2em;
          font-size: 1em;
          padding: 0.7em 0;
          opacity: 0.85;

          &.selected {
            opacity: 1;
            background: #fff;
            color: #000;
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          text-align: left;
          margin: 0.9em 0 0.9em 1em;
          padding: 0;
          font-family: AvertaSemiBold, sans-serif;

          &:last-child {
            margin-bottom: 0;
          }
          a {
            font-family: AvertaExtraBold, sans-serif;
            text-decoration: none;
            color: #fff;
          }
        }
      }

      .filter-range {
        text-align: left;
        margin: 0 0 2em 1em;
        font-family: AvertaExtraBold, sans-serif;
        font-size: 1.1em;
      }

      .rc-slider-container {
        margin-left: 1em;
        margin-right: 1em;
        max-width: 420px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
      }

      .size-or-divider {
        max-width: 420px;
        font-style: italic;
        margin: 1.5em 0;
      }

      label {
        display: flex;
        padding: 0.1em 0;
        input[type="checkbox"] {
          display: none;
        }
        .checkbox {
          display: inline-block;
          width: 0.9em;
          height: 0.9em;
          border: 2px solid #fff;
          border-radius: 0.2em;
          margin-right: 0.5em;
          img {
            display: none;
          }

          &.selected {
            background-color: #fff;

            img {
              display: block;
              margin-top: -15%;
              margin-left: -15%;
              width: 130%;
              height: 130%;
            }
          }
        }
      }
    }
  }
}

.Results-filter-products-container {
  .FilterList-container {
    display: none;
    &.active {
      display: none;
    }
  }
}

@media screen and (min-width: 389px) {
  .FilterList-container {
    .FilterList-filters {
      .filter  {
        br {
          display: block;
        }
        ul.Filter-decade {
          display: block;
          width: 49%;
          float: left;
        }
      }
    }
  }
}

@media screen and (min-width: 450px) {
  .FilterList-container {
    font-size: 16px;

    .FilterList-filters {
      margin: 5em 1.6em 2em 1.6em;
      transition: none;

      .filter  {
        br {
          display: block;
        }

        ul.Filter-decade {
          //background: #f00;
          display: block;
          width: 49%;
          float: left;
        }
      }
    }
  }
}

@media screen and (min-width: 550px) {
  .FilterList-container {
    font-size: 17px;

    .FilterList-filters {
      margin-left: auto;
      margin-right: auto;
      max-width: 420px;
    }
  }
}

@media screen and (min-width: 700px) {
  .FilterList-container {
    font-size: 18px;
  }
}

@media screen and (min-width: 840px) {
  .FilterList-container {
    font-size: 19px;
  }
}

@media screen and (min-width: 1000px) {
  .FilterList-container {
    display: none;
    &.active {
      display: none;
    }
    &.CategoryWizardOverlay-container {
      display: block;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      overflow-x: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
      &.active {
        overflow-x: hidden;
      }
    }
  }
  .Results-filter-products-container {
    .FilterList-container {
      font-size: 16px;
      position: relative;
      display: block;
      opacity: 1;
      height: auto;
      background: none;
      color: #000;
      overflow: auto;
      flex-grow: 0;
      flex-shrink: 0;
      width: 13.75em;
      padding-right: 0;
      text-align: left;
      z-index: 1;
      &.active {
        display: block;
      }

      .FilterList-container-inner {
        padding-right: 1.25em;
        width: 12.5em;
        &.sticky {
          width: 13.75em;
          padding: 1.5em 1.25em 1.5em 0;
          display: block;
          position: fixed;
          top: 3em;
          height: 100%;
          overflow-y: scroll;
          margin: 0;
          box-sizing: border-box;
          overflow-x: auto;
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
        }
        &.sticky::-webkit-scrollbar {
          display: none;
        }
      }

      .rc-slider-rail {
        height: 4px;
        background-color: #ccc;
        border-radius: 6px;
      }
      .rc-slider-track {
        background-color: #000;
      }
      .rc-slider-handle {
        background-color: #fff;
        border: solid 4px #000;
        opacity: 1;
      }
      .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
        border-color: #000;
        box-shadow: 0 0 0 6px #000;
      }
      .rc-slider-handle:focus-visible {
        border-color: #000;
        box-shadow: 0 0 0 3px #000;
      }
      .rc-slider-handle-click-focused:focus {
        border-color: #000;
      }
      .rc-slider-handle:hover {
        border-color: #000;
      }
      .rc-slider-handle:active {
        border-color: #000;
        box-shadow: 0 0 5px #000;
      }

      .Header-sub-menu-top {
        transition: none;
        display: none;
      }
      .FilterList-filters {
        margin: 0;
        padding-bottom: 2.5em;

        .filter {
          border-bottom: 1px solid #ddd;

          h5 {
            font-family: Averta, sans-serif;
            color: #333;
          }

          .gender-button-container {
            button {
              color: #000;
              border: 2px solid #000;
              padding: 0.5em 0;
              opacity: 0.7;

              &.selected {
                opacity: 1;
                background: #000;
                color: #fff;
              }
            }
          }

          br {
            display: none;
          }
          ul {
            &.Filter-decade {
              float: none;
              width: auto;
            }
            li {
              a {
                font-family: AvertaSemiBold, sans-serif;
                color: #000;
              }
            }
          }
          .filter-range {
            font-family: AvertaSemiBold, sans-serif;
          }
          .size-or-divider {
            width: 100%;
            text-align: center;
          }
          label {
            .checkbox {
              border: 2px solid #000;
              &.selected {
                background-color: #000;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1150px) {
  .Results-filter-products-container {
    .FilterList-container {
      font-size: 16px;
      width: 14.75em;
      padding-right: 0;

      .FilterList-container-inner {
        padding-right: 1.75em;
        width: 13em;

        &.sticky {
          width: 14.75em;
          padding: 1.5em 1.75em 1.5em 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .Results-filter-products-container {
    .FilterList-container {
      font-size: 17px;
      width: 15.75em;
      padding-right: 0;
      .FilterList-container-inner {
        padding-right: 2.25em;
        width: 13.5em;
        &.sticky {
          width: 15.75em;
          padding: 1.25em 2.25em 1.25em 0;
        }
      }
    }
  }
}
