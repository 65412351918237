.ResultsTitleBar-Bar {
  background: #000;

  .ResultsTitleBar-container {
    display: block;

    h1 {
      color: #fff;
      font-weight: normal;
      font-family: 'AvertaExtraBold', sans-serif;
      font-size: 1em;
      padding: 1em 0 0 0;
      margin: 0;
      text-align: left;
    }

    &.no-results-count h1 {
      padding: 1em 0;
    }

    .ResultsTitleBar-result-count {
      font-size: 0.85em;
      margin: 0;
      font-family: AvertaSemiBold, sans-serif;
      text-align: left;
      color: #aaa;
      padding: 0.4em 0 1.15em 0;

      .divider {
        display: none;
      }
    }
    .FilterButton-filter-container {
      display: none;
    }
  }
}

.PopcornLinks {
  margin: 0;
  padding: 0;
  text-align: left;

  ul {
    list-style: none;
    padding: 0;
    margin: 0.5em 1em -1em 1em;

    li {
      display: inline-flex;
      white-space: nowrap;
      padding: 0;
      margin: 0 0.5em 0 0;
      align-items: center;

      a {
        font-size: 0.8em;
        color: #222;
        margin-right: 0.5em;
      }
    }
  }
}

@media screen and (min-width: 390px) {
  .ResultsTitleBar-Bar {

    .ResultsTitleBar-container {
      display: flex;

      h1 {
        padding: 1em 0;
        flex-grow: 1;
      }

      .ResultsTitleBar-result-count {
        flex-shrink: 1;
        padding: 0;
        margin: 0;
        align-self: center;
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .PopcornLinks ul li a {
    font-size: 14px;
  }
}

@media screen and (min-width: 650px) {
  .ResultsTitleBar-Bar {

    .ResultsTitleBar-container {

      h1 {
        flex-grow: 0;
        flex-shrink: 1;
      }

      .ResultsTitleBar-result-count {
        flex-shrink: 1;
        padding: 0;
        margin: 0;
        align-self: center;
        .divider {
          display: inline;
        }
      }
      .FilterButton-filter-container {
        position: initial;
        display: flex;
        width: auto;
        justify-content: flex-end;
        flex-grow: 1;
        text-align: right;
        align-self: center;
        align-items: flex-end;


        button {
          margin: 0;
          display: flex;
          border-radius: 1.5em;
          background: #fff;
          color: #000;
          font-size: 0.85em;
          padding: 0.4em 1em 0.45em 1em;
          border: none;

          img {
            filter: none;
            height: auto;
            width: 1em;
            margin-right: 0.33em;
          }
        }
      }
    }
  }
  .PopcornLinks ul {
    margin-left: 1.33em;
  }
}

@media screen and (min-width: 850px) {
  .PopcornLinks ul {
    margin-left: 1.7em;
  }
}

@media screen and (min-width: 1000px) {
  .ResultsTitleBar-Bar {
    .ResultsTitleBar-container {
      h1 {
        flex-grow: 1;
        flex-shrink: 0;
      }
      .ResultsTitleBar-result-count {
        flex-shrink: 1;
        .divider {
          display: none;
        }
      }
      .FilterButton-filter-container {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .PopcornLinks ul {
    margin-left: 5vw;
  }
}

@media screen and (min-width: 1600px) {
  .PopcornLinks  {
    max-width: 1416px;
    margin-left: auto;
    margin-right: auto;

    ul {
      margin-left: 0;
    }
  }
}
