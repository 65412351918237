.Results-filter-products-container {
  margin-top: 2em;
  margin-bottom: 2em;
}

.loading-container {
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  display: none;

  &.visible {
    display: block;
  }

  .loading-animation {
    height: 50px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 500px) {
  .loading-container {
    .loading-animation {
      height: 70px;
    }
  }
}

@media screen and (min-width: 1000px) {
  .Results-filter-products-container {
    display: flex;
  }
}
