.FilterButton-filter-container {
  position: fixed;
  bottom: 3%;
  width: 100%;
  text-align: center;
  z-index: 10;

    button {
      display: flex;
      margin: 0 auto;
      border-radius: 1.5em;
      font-size: 1.07em;
      font-family: AvertaExtraBold, sans-serif;
      padding: 0.5em 1.2em 0.55em 1.2em;
      border: none;
      background: #000;
      color: #fff;
      box-shadow: 0 4px 12px rgba(0,0,0,0.3);

      img {
        filter: invert(99%) sepia(75%) saturate(0%) hue-rotate(29deg) brightness(111%) contrast(100%);
        height: auto;
        width: 1.1em;
        margin-right: 0.4em;
        align-self: center;
      }
    }
}

@media screen and (min-width: 410px) {
  .FilterButton-filter-container {
    bottom: 4%;

    button {
      font-size: 1.2em;

      img {
        width: 1.2em;
        margin-right: 0.4em;
      }
    }
  }
}

@media screen and (min-width: 650px) {
  .FilterButton-filter-container {
    display: none;
  }
}
